@use 'colors';

// Functions
@function add($value1, $value2, $return-calc: true) {
  @if $value1 == null {
    @return $value2;
  }

  @if $value2 == null {
    @return $value1;
  }

  @if type-of($value1) == number and type-of($value2) == number and comparable($value1, $value2) {
    @return $value1 + $value2;
  }

  @return if($return-calc == true, calc(#{$value1} + #{$value2}), $value1 + unquote(" + ") + $value2);
}

@function subtract($value1, $value2, $return-calc: true) {
  @if $value1 == null and $value2 == null {
    @return null;
  }

  @if $value1 == null {
    @return -$value2;
  }

  @if $value2 == null {
    @return $value1;
  }

  @if type-of($value1) == number and type-of($value2) == number and comparable($value1, $value2) {
    @return $value1 - $value2;
  }

  @return if($return-calc == true, calc(#{$value1} - #{$value2}), $value1 + unquote(" - ") + $value2);
}

// Typography
$font-size-base: 1rem !default;
$font-family-sans-serif: "Nunito", sans-serif !default;
$font-family-headings: "Montserrat", sans-serif !default;
$page-heading-size: 1.5rem !default;

// Shadows
$box-shadow: 0 .085rem .12rem rgba(colors.$grey6, 0.07),
0 .125rem .2rem rgba(colors.$grey6, 0.07),
0 .25rem .4rem rgba(colors.$grey6, 0.07),
0 .5rem .8rem rgba(colors.$grey6, 0.07) !default;

// Spacing
$spacer: 1rem !default;
$grid-gutter: $spacer !default;
$border-radius: 8px !default;

// Layout
$header-bar-height: 70px !default;
$header-bar-zIndex: 100 !default;
$global-action-menu-height: 0 !default;

$global-action-menu-zIndex: $header-bar-zIndex - 1 !default;
$main-content-top-offset: $header-bar-height !default;
$main-max-width: 1480px !default;

// Form controls
$form-control-error-color: colors.$red !default;
$form-label-color: colors.$grey6 !default;
$form-label-font-size: 14px !default;
$form-label-font-weight: 700 !default;
$form-label-bottom-margin: 3px !default;
$form-error-label-color: colors.$red !default;
$input-height: auto !default;
$input-border-width: 1px !default;
$input-padding-y: .38rem !default;
$input-padding-x: .5rem !default;
$input-border-color: colors.$grey3 !default;
$input-background-color: colors.$white !default;
$input-color: colors.$grey6 !default;
$input-placeholder-color: colors.$grey3 !default;
$input-font-size: $font-size-base !default;
$input-disabled-border-color: colors.$grey3 !default;
$input-disabled-background-color: colors.$white !default;
$input-focus-border-color: colors.$primary !default;
$input-focus-background-color: colors.$white !default;
$input-focus-box-shadow-color: colors.$primary !default;
$input-focus-box-shadow: 0 0 0 .2rem rgba($input-focus-box-shadow-color, .25) !default;
$input-focus-color: colors.$grey6 !default;
$input-error-border-color: colors.$red !default;
$input-error-background-color: colors.$white !default;
$input-error-color: colors.$red !default;
$input-error-focus-border-color: colors.$red !default;
$input-error-focus-box-shadow: 0 0 0 .2rem rgba(colors.$red, .25) !default;
$input-error-focus-background-color: colors.$white !default;
$input-error-focus-color: colors.$grey6 !default;
$input-btn-font-size: .9rem !default;
$custom-control-indicator-size: 1rem !default;
$custom-switch-width: $custom-control-indicator-size * 1.75 !default;
$custom-switch-indicator-border-radius: $custom-control-indicator-size / 2 !default;
$custom-control-indicator-border-width: $input-border-width !default;
$custom-switch-indicator-size: subtract($custom-control-indicator-size, $custom-control-indicator-border-width * 4) !default;

// Transitions
$button-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out !default;

// Navigation
$context-nav-full-width: 240px !default;
$context-nav-small-width: 90px !default;
$sub-nav-full-width: 325px !default;
$sub-nav-padding-y: 1rem !default;
$sub-nav-padding-x: 2.5rem !default;

// Backdrop
$backdrop-zIndex: 1000 !default;

// Side menu
$side-menu-header-height: 100px !default;
$side-menu-action-buttons-height: 70px !default;
$side-menu-content-height: calc(100% - (#{$side-menu-header-height} + #{$side-menu-action-buttons-height})) !default;
$side-menu-backdrop-zIndex: 1000 !default;
$side-menu-zIndex: 1100 !default;
$menu-transition: .3s ease-in-out !default;

// Modals
$modal-default-width: 540px !default;
$modal-small-width: 360px !default;
$modal-large-width: 880px !default;
$modal-header-height: 70px !default;
$modal-action-buttons-height: 60px !default;
$modal-body-height: calc(#{$modal-header-height} + #{$modal-action-buttons-height}) !default;
$modal-backdrop-zIndex: 1300 !default;
$modal-zIndex: 1400 !default;
$modal-padding: 1.4rem !default;

// Notifications
$notification-container-width: 480px !default;
$notification-container-top-offset: 2rem !default;
$notification-container-right-offset: 1rem !default;
$notification-container-zIndex: 1400 !default;
$notification-item-spacing: 1rem !default;
$notification-item-initial-transform-x: calc(#{$notification-container-width} + #{$notification-container-right-offset}) !default;
$notification-item-text-color: colors.$grey7 !default;
$notification-item-timestamp-color: colors.$grey4 !default;
$notification-item-dismiss-btn-color: colors.$grey4 !default;
$notification-item-background-color: colors.$white !default;
$notification-item-default-color: colors.$blue !default;
$notification-item-success-color: colors.$green !default;
$notification-item-info-color: colors.$purple !default;
$notification-item-warning-color: colors.$orange !default;
$notification-item-error-color: colors.$red !default;

// Dropdowns
$dropdown-padding-y: 0 !default;
$dropdown-item-padding-y: .4rem !default;
$dropdown-item-padding-x: .5rem !default;
$dropdown-link-active-color: colors.$grey7 !default;
$dropdown-link-active-bg: colors.$grey !default;
$dropdown-link-color: colors.$grey7 !default;
$dropdown-link-hover-bg: colors.$grey !default;

@-moz-keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@mixin loading-icon($height: "18px", $width: "18px") {
  border-radius: 50%;
  width: #{$width};
  height: #{$height};
  border: .15rem solid rgba(255, 255, 255, .25);
  border-top-color: rgb(255, 255, 255);
  animation: loading .50s infinite linear;
  margin: 0 auto;
}
