@use 'app-colors' as c;
@use 'app-variables' as v;

.wrapper {
  position: fixed;
  height: v.$active-facility-banner-height;
  top: v.$header-bar-height;
  left: 0;
  right: 0;
  z-index: v.$header-bar-zIndex - 1;
  padding: 0 v.$spacer * 2;

  .container {
    max-width: v.$main-max-width;
    height: 100%;
    display: flex;
    align-items: center;
    //justify-content: center;
    margin: auto;
    background: c.$grey2;
    font-size: v.$font-size-base * .9;
    //text-align: center;
    border-bottom-left-radius: v.$border-radius;
    border-bottom-right-radius: v.$border-radius;
    color: c.$grey5;

    .grid {
      flex: 1;
    }

    .nameContainer {
      padding-left: v.$spacer;
    }

    .addressContainer {
      padding-right: v.$spacer;

      .address {
        text-align: right;
        max-width: 100% !important;
      }
    }

    .name {
      font-weight: 700;
      color: c.$grey6;
    }
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    padding: 0 v.$spacer;
  }
}

@media screen and (max-width: 465px) {
  .wrapper {
    .container {
      .nameContainer {
        text-align: center;
      }

      .addressContainer {
        display: none;
      }
    }
  }
}
