@forward 'variables';
@use 'variables' as v;
@use 'colors' as c;

// Layout
$nav-full-width: 0 !default;
$header-bar-height: 70px !default;
$main-content-top-offset: $header-bar-height !default;
$main-content-padding: v.$spacer * 2 !default;
$main-content-max-width: calc(1480px + (#{$main-content-padding} * 2)) !default;
$active-facility-banner-height: 40px !default;

// Z-Indexes
$header-bar-zIndex: 100 !default;

// Patients
$patient-card-height: 140px !default;

// Video
$video-menu-bar-height: 70px !default;
