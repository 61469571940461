@use 'sass:color';

/*
    Colors
*/


// Convert a hex value to comma-delimited rgb values
// In: #000
// Out: 0,0,0
@function derive-rgb-values-from-hex($hex) {
  @return red($hex), green($hex), blue($hex);
}

$white: #FFFFFF !default;
$base: #F5F7FA !default;
$grey: #EDEFF1 !default;
$grey2: #D9DDE1 !default;
$grey3: #B6BEC7 !default;
$grey4: #7C8C9A !default;
$grey5: #5A6672 !default;
$grey6: #394148 !default;
$grey7: #2D3436 !default;
$black: #000000 !default;
$green: #27AE60 !default;
$blue: #0078d7 !default;
$blue-darker: color.scale($blue, $lightness: -10%) !default;
$red: #C0392B !default;
$yellow: #F2CC35 !default;
$orange: #E67E22 !default;
$purple: #9B59B6 !default;
$purple-darker: color.scale($purple, $lightness: -10%) !default;
$pink: #FD79A8 !default;
$pink: #FD79A8 !default;

$primary: $blue !default;
$primary-darker: color.scale($primary, $lightness: -10%) !default;
$success: $green !default;
$success-darker: color.scale($success, $lightness: -10%) !default;
$info: $purple !default;
$info-darker: color.scale($info, $lightness: -10%) !default;
$warning: $orange !default;
$warning-darker: color.scale($warning, $lightness: -10%) !default;
$danger: $red !default;
$danger-darker: color.scale($danger, $lightness: -10%) !default;

$colors: () !default;
$colors: map-merge(
    (
      "white": $white,
      "base": $base,
      "grey": $grey,
      "grey2": $grey2,
      "grey3": $grey3,
      "grey4": $grey4,
      "grey5": $grey5,
      "grey6": $grey6,
      "grey7": $grey7,
      "black": $black,
      "green": $green,
      "blue": $blue,
      "blue-darker": $blue-darker,
      "red": $red,
      "yellow": $yellow,
      "orange": $orange,
      "purple": $purple,
      "purple-rgb": #{derive-rgb-values-from-hex($purple)},
      "purple-darker": $purple-darker,
      "pink": $pink,
      "primary": $primary,
      "primary-rgb": #{derive-rgb-values-from-hex($primary)},
      "primary-darker": $primary-darker,
      "primary-box-shadow": rgba($primary, .25),
      "success": $success,
      "success-rgb": #{derive-rgb-values-from-hex($success)},
      "success-darker": $success-darker,
      "info": $info,
      "info-darker": $info-darker,
      "warning": $warning,
      "warning-rgb": #{derive-rgb-values-from-hex($warning)},
      "warning-darker": $warning-darker,
      "danger": $danger,
      "danger-rgb": #{derive-rgb-values-from-hex($danger)},
      "danger-darker": $danger-darker,
      "danger-lighter": rgba($danger, .35),
      "royalBlue": #0078D7,
      "theme-grey": #393E44,
      "theme-grey-2": #767479,
      "theme-dark-blue": #066895,
      "theme-dark-blue-2": #6AA4BF,
      "theme-blue": #4894EE,
      "theme-blue-2": #91BFF5,
      "theme-teal": #00D2D9,
      "theme-teal-2": #99EDF0,
      "theme-light-grey": #C5C3C5,
      "theme-light-dark-blue": #CDE1EA,
      "theme-light-blue": #DAEAFC,
      "theme-light-teal": #CCF6F7,
    ),
    $colors
);

$theme-colors: () !default;
$theme-colors: map-merge(
    (
      "primary": $primary,
      "success": $success,
      "info": $info,
      "warning": $warning,
      "danger": $danger,
    ),
    $theme-colors,
);

@each $name,
$color in $colors {
  @if (str-index($name, "-rgb") == null) {
    .font-#{$name} {
      color: $color;
    }

    .bg-#{$name}-50 {
      background-color: rgba($color, .0865);
    }

    .bg-#{$name} {
      background-color: $color;
    }
  }
}

@function color($key) {
  @if not map-has-key($colors, $key) {
    @warn "Key `#{$key}` not found in $colors map.";
  }

  @return map-get($colors, $key);
}

.label-primary {
  background-color: color("blue");
}

.label-success {
  background-color: color("green");
}

.label-danger {
  background-color: color("red");
}

.label-warning {
  background-color: color("orange");
}
