@use 'app-colors' as c;
@use 'app-variables' as v;

.wrapper {
  position: fixed;
  z-index: 1000;
  width: 100%;
  display: flex;
  height: v.$header-bar-height;
  background-color: c.$danger;
  color: c.$white;
  font-size: v.$font-size-base * 1.5;
  text-align: center;
  padding: v.$spacer;
  transform: translateY(0);
  transition: transform .165s linear;
  align-items: center;
  justify-content: center;

  &.hidden {
    transform: translateY(-100%);
  }
}

@media screen and (max-width: 650px) {
  .wrapper {
    padding: v.$spacer * .5 v.$spacer;
    font-size: v.$font-size-base * 1.2;
  }
}
